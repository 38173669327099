import React, { useState } from 'react';
import {
  Accordion, Button, Form, InputGroup,
} from 'react-bootstrap';
import { FloatingButton } from '../components';

export default ({ currentUser }) => {
  const [error, setError] = useState(null);
  const [serial, setSerial] = useState('');

  const submit = async (evt) => {
    evt.preventDefault();
    setError(null);

    if (!serial) {
      setError('Serial number is required');
      return;
    }

    // Prefetch the image to ensure it exists
    const resp = await fetch(`/api/omega/${serial}`);
    if (!resp.ok) {
      setError(`Failed to load image: ${resp.status}`);
    } else {
      window.location.href = `/api/omega/${serial}`;
    }
  };

  return (
    <div className="main">
      <img className="masthead" src="/app-icon.png" alt="Project Omega Logo" />
      <h3 className="mb-5 text-center">
        Project Omega Frequency Response Lookup:
      </h3>

      <FloatingButton currentUser={currentUser} />

      <Form className="center-me" onSubmit={submit} style={{ width: '100%' }}>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Your Omega's Serial Number"
            onChange={(evt) => setSerial(evt.target.value)}
            value={serial}
          />
          <Button variant="info" type="submit">
            Search
          </Button>
        </InputGroup>

        {error && (
          <pre style={{ color: 'red', textAlign: 'center' }}>{error}</pre>
        )}
      </Form>

      <Accordion className="center-me mb-3">
        <Accordion.Item eventKey={0} style={{ width: '512px' }}>
          <Accordion.Header>What is this tool?</Accordion.Header>
          <Accordion.Body>
            <p>
              Here you can find the frequency response of your Omega unit
              (measured on the Breul and Kjaer 4128C). Just type in the serial
              number above. The serial number is located on the headphone’s
              baffle (hidden under the removable ear-pads).
            </p>

            <p>
              You can use this unique frequency response measurement of your
              unit to EQ-correct the headphone for professional applications, or
              just for your personal records. Happy listening!
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
