import { Modal, Button, Form } from 'react-bootstrap';
import React, { useState } from 'react';

export default ({ show, onHide, onComplete }) => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState(null);
  onComplete;

  const login = async (evt) => {
    evt.preventDefault();
    setIsLoggingIn(true);
    setError(null);

    const resp = await fetch('/api/admin/login', {
      method: 'post',
      body: JSON.stringify({ ...formData }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (resp.ok) {
      const token = await resp.text();
      window.localStorage.setItem('token', token);
      onComplete && onComplete();
    } else {
      setError(`Failed to log in: ${resp.status}`);
    }

    setIsLoggingIn(false);
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Admin Log In</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={login}>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              onChange={(evt) => setFormData({ ...formData, username: evt.target.value })}
              value={formData.username}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="End-to-end encryption has you covered 💚"
              onChange={(evt) => setFormData({ ...formData, password: evt.target.value })}
              value={formData.password}
            />
          </Form.Group>

          {error && (<pre style={{ color: 'red', textAlign: 'center' }}>{error}</pre>)}

          <Button disabled={isLoggingIn} variant={isLoggingIn ? 'secondary' : 'info'} type="submit">
            Log In
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
