import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiLogInCircle, BiUpload } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import LoginModal from './LoginModal';

export default ({ currentUser }) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const onClick = () => {
    if (currentUser) {
      history.push('/admin');
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <LoginModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onComplete={() => window.location.reload()}
      />
      <Button className="floating-login-btn" variant="info" onClick={onClick}>
        {currentUser ? (
          <div className="alignIconWithText">
            Go Uploading &nbsp;
            {' '}
            <BiUpload />
          </div>
        ) : (
          <div className="alignIconWithText">
            Log In &nbsp;
            {' '}
            <BiLogInCircle />
          </div>
        )}
      </Button>
    </>
  );
};
