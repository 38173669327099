import React, { useState } from 'react';
import { Button, Form, Toast } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

const toMb = (size) => (size / 1000000).toFixed(2);

export default () => {
  const history = useHistory();

  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState(null);
  const [overwrite, setOverwrite] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);

  const dismiss = () => {
    setSuccess(false);
    setFiles(null);
  };

  const uploadFile = async (file) => {
    if (file.size > 16000000) {
      setErrors([...errors, `File ${file.name} is bigger than ${toMb(file.size)}MB and was not uploaded`]);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const noExt = file.name.slice(0, file.name.lastIndexOf('.'));

    const resp = await fetch(`/api/admin/${noExt}${overwrite ? '?override=true' : ''}`, {
      method: 'PUT',
      body: formData,
      headers: { 'auth-token': window.localStorage.getItem('token') },
    });

    if (!resp.ok) {
      setErrors([...errors, `Failed to upload ${file.name}: ${resp.status}`]);
    }
  };

  const submit = async (evt) => {
    evt.preventDefault();
    setErrors([]);
    setSuccess(false);
    if (!files.length) {
      setErrors('File is required');
    }

    setUploading(true);
    // Not using Promise.all because it can frustrate the backend sometimes D:
    // eslint-disable-next-line no-restricted-syntax
    for (const file of Array.from(files)) {
      // eslint-disable-next-line no-await-in-loop
      await uploadFile(file);
    }

    setUploading(false);

    if (!errors.length) {
      setSuccess(true);
      // Make this go away in 5 seconds
      setTimeout(dismiss, 5000);
    }
  };

  return (
    <>
      <Button variant="info" className="floating-back-btn" onClick={() => history.push('/')}>
        <div className="alignIconWithText">
          <BiArrowBack style={{ marginRight: '0.5rem' }} />
          {' '}
          Go back
        </div>
      </Button>

      <Toast
        animation
        show={success}
        onClose={dismiss}
        className="floatingToast"
      >
        <Toast.Header>
          <strong className="me-auto">Upload Complete!!</strong>
        </Toast.Header>
        <Toast.Body>
          <span>
            Successfully uploaded:
            {' '}
            <span style={{ fontFamily: 'monospace' }}>{files ? Array.from(files).map((x) => x.name).join(', ') : ''}</span>
          </span>
        </Toast.Body>
      </Toast>

      <div className="main">
        <h2 className="mb-3">Upload New Measurements</h2>

        <Form className="center-me" onSubmit={submit} style={{ width: '100%' }}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              multiple
              onChange={(evt) => setFiles(evt.target.files)}
            />

            <Form.Text className="text-muted">
              The filename (without extension) will be used as the Serial Number that is
              looked up on the main page!
            </Form.Text>
          </Form.Group>

          {!!errors.length && (<pre style={{ color: 'red', textAlign: 'center' }}>{Array.from(new Set(errors)).sort().join('\n')}</pre>)}

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <div className="d-inline-flex flex-row justify-content-between align-items-center w-100">
              <Form.Check type="checkbox" label="Overwrite Existing File" onChange={(evt) => setOverwrite(evt.target.checked)} value={overwrite} />
              <Button disabled={uploading} variant="info" type="submit">Upload</Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};
