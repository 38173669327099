/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { LoadAdmin, ValidateAdmin } from './auth';
import { Home, Upload } from './pages';

export default () => {
  ValidateAdmin();
  const currentUser = LoadAdmin();
  ReactDOM.render(
    <Router>
      <Route exact path="/" component={() => (<Home currentUser={currentUser} />)} />
      {currentUser && (<Route exact path="/admin" component={Upload} />)}
    </Router>,
    document.getElementById('root'),
  );
};
